<template>
    <div class="succeed-main">
        <div class="pay-state">
            <img
                class="pay-icon"
                src="@/assets/img/pay-state-icon.png"
                alt=""
            />
            <div class="pay-text">支付成功</div>

            <van-skeleton style="width: 100%" title :row="1" :loading="loading">
                <div class="pay-type">
                    {{ companyInfo.status_format }}
                </div>
                <div class="pay-tz">{{ companyInfo.desc }}</div>
            </van-skeleton>
        </div>
        <div class="share-to">
            <div class="share-title">分享我的信用到</div>
            <div class="share-img">
                <div @click="shareFun" class="share-img-text">
                    <img src="@/assets/img/weixin-icon.png" alt="" />
                    <span>微信好友</span>
                </div>
                <div @click="shareFun" class="share-img-text">
                    <img src="@/assets/img/pengyouquan.png" alt="" />
                    <span>朋友圈</span>
                </div>
            </div>
            <div class="share-text">
                <div>{{ copyText }}</div>
                <div @click="copyFun">复制分享文案</div>
            </div>
        </div>
        <aboutUsC />
        <van-notify v-model="show" type="success">
            <van-icon name="bell" style="margin-right: 4px" />
            <span>请点击右上角 ••• 进行分享</span>
        </van-notify>
    </div>
</template>
<script>
import preventBack from "vue-prevent-browser-back";
import aboutUsC from "@/components/aboutUs";
import { orderInfoApi } from "@/api/index.js";
export default {
    components: {
        aboutUsC,
    },
    mixins: [preventBack],
    data() {
        return {
            loading: true,
            show: false,
            copyText:
                "我已加入优信立信计划，有立信牌匾为证，营造诚实守信良好环境，人人有责",
            payStateText: [
                {
                    state: "材料审核中",
                    inform: "审核结果会手机短信通知",
                },
                {
                    state: "牌匾制作中",
                    inform: "审核结果会手机短信通知",
                },
                {
                    state: "已发货",
                    inform: "快递单号: xxxxxxxxxxxx",
                },
            ],
            payStateIndex: 0,
            cid: "",
            companyInfo: {
                desc: "",
                status_format: "",
            },
        };
    },
    created() {
        this.cid = this.$route.params.cid;
        if (this.cid) {
            this.orderInfoApiFun();
            //微信分享触发事件
            let option = this.util.shareData();
            option.link = `${location.origin}/${this.cid}`;
            // console.log(option)
            this.util.weixinShareFun(option);
        } else {
            this.$toast("企业ID为空");
        }
    },
    methods: {
        orderInfoApiFun() {
            let self = this;
            orderInfoApi(self.cid).then((res) => {
                // console.log(res.data);
                if(res.code == 200){
                    self.companyInfo.desc = res.data.desc;
                    self.copyText = res.data.share_info;
                    self.companyInfo.status_format = res.data.status_format;
                    self.loading = false;
                }else{
                    self.$toast(res.msg || '服务器请求错误')
                }                
            });
        },
        //复制文案
        copyFun() {
            let input = document.createElement("input"); // 创建input对象
            input.value = this.copyText; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$toast("复制成功");
        },
        //微信分享
        shareFun() {
            if (!this.$store.state.is_weixin_app) return this.$toast("请在微信中打开");
            this.show = true;
            setTimeout(() => {
                this.show = false;
            }, 2000);
        },
    },
};
</script>
<style lang="less" scoped>
.van-skeleton__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
}
.succeed-main {
    width: 375px;
    height: 754px;
    min-height: 100vh;
    background: url("~@/assets/img/pay-succeed.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pay-state {
        width: 350px;
        height: 174px;
        // height: auto;
        background: url("~@/assets/img/pay-state.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .pay-icon {
            width: 40px;
            height: 40px;
            margin-top: 17px;
        }
        .pay-text {
            font-size: 20px;
            color: #333333;
            font-weight: 700;
            margin-top: 5px;
        }
        .pay-type {
            font-size: 21px;
            color: #bd0406;
            font-weight: 700;
            margin-top: 5px;
        }
        .pay-tz {
            color: #666666;
            font-size: 14px;
            margin-top: 5px;
        }
    }
    .share-to {
        width: 350px;
        height: 240px;
        background: url("~@/assets/img/share-to.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 23px;
        .share-title {
            font-size: 20px;
            font-weight: 700;
            margin-top: 15px;
        }
        .share-img {
            width: calc(80px + 68px);
            height: 60px;
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            margin-bottom: 15px;
            .share-img-text {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                color: #333333;
            }
            img {
                width: 40px;
                height: 40px;
            }
        }
        .share-text {
            width: 321px;
            height: 85px;
            background: #f1f1f1;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            div {
                &:nth-child(1) {
                    width: 294px;
                    color: #555555;
                    font-size: 14px;
                    margin: 14px 20px 0 9px;
                }
                &:last-child {
                    width: 80px;
                    height: 18px;
                    border-radius: 5px;
                    background: #09bb07;
                    color: #ffffff;
                    font-size: 11px;
                    line-height: 18px;
                    text-align: center;
                    margin-left: auto;
                    margin-right: 9px;
                    margin-top: 5px;
                }
            }
        }
    }
}
</style>